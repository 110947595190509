// APP CONSTATNS

const app = {
    DEBUG: false,
    MOCK: false,
    APP_NAME: 'Latitude',

    // Live Server Details
    // API_URL: 'https://lta.api.live.scorchsoft.dev/api/',
    // SERVER_URL: 'https://lta.api.live.scorchsoft.dev/',

    // Staging server details
    API_URL: 'https://lta.api.stag2.scorchsoft.dev/api/',
    SERVER_URL: 'https://lta.api.stag2.scorchsoft.dev/',

    // Local server details
    API_URL_DEV: 'http://lta.api.local/api/',
    SERVER_URL_DEV: 'http://lta.api.local/',

    STACKABLE_KEY: 'bBAtfmcHmgAz',
    GALLERY_CONTAINER: '2n6Lbqw8oAzpQB5Zt',
    AUTH_KEY: 'latitude-auth-key',
    LOCALSTORAGE_EXPIRES_KEY: 'latitude-expire-key',
    LOCALSTORAGE_EXPIRES: '60' // minutes
};

export default app;
